var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "business-header" }, [
        _c(
          "div",
          { staticClass: "header-left" },
          [
            _vm.showModel === 1
              ? _c("en-icon", {
                  staticClass: "back-icon",
                  attrs: {
                    name: "iconfanhuishangcengji",
                    size: "18px",
                    color: "#A9B5C6",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleBackIconClick.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm.showTemplateName && _vm.showTemplateIcon
              ? _c(
                  "div",
                  { staticClass: "header-icon" },
                  [
                    _c("en-icon", {
                      attrs: {
                        name: _vm.showTemplateIcon,
                        size: "16",
                        color: "#FCFCFC",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showTemplateName && !_vm.showTemplateIcon
              ? _c(
                  "div",
                  { staticClass: "header-icon" },
                  [
                    _c("en-icon", {
                      attrs: {
                        name: "iconmoban-yewujianmo1",
                        size: "16",
                        color: "#FCFCFC",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.showTemplateName)),
            ]),
            _vm.businessData
              ? _c("billStatus", {
                  attrs: {
                    billStatus: _vm.businessData.mdInfo.billStatus,
                    billStatusName: this.businessData.mdInfo.billStatusName,
                  },
                })
              : _vm._e(),
            _vm.businessData && _vm.businessData.mdInfo.parentId
              ? _c("span", { staticClass: "parent" }, [
                  _vm._v(_vm._s("上级:" + _vm.businessData.mdInfo.parentName)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.voucherBtnVisible && _vm.businessData.openType === "2"
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "yiyouhangcheng",
                      "icon-color": "#3e90fe",
                      type: "text",
                    },
                    on: { click: _vm.handleVoucherButtonClick },
                  },
                  [_vm._v(" 关联凭证 ")]
                )
              : _vm._e(),
            _vm.businessData &&
            _vm.businessData.mdData &&
            _vm.businessData.mdData.originData.tripId &&
            _vm.businessData.openType === "2"
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "yiyouhangcheng",
                      "icon-color": "#3e90fe",
                      type: "text",
                    },
                    on: { click: _vm.handleTripButtonClick },
                  },
                  [_vm._v(" 行程单 ")]
                )
              : _vm._e(),
            (_vm.businessData &&
              _vm.businessData.openType === "2" &&
              _vm.businessData.mdData &&
              _vm.businessData.mdData.originData.attRecordId) ||
            _vm.businessParams.attRecordId
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "guanliankaoqin",
                      "icon-color": "#3e90fe",
                      type: "text",
                    },
                    on: { click: _vm.handleAttRecordButtonClick },
                  },
                  [_vm._v(" 关联考勤 ")]
                )
              : _vm._e(),
            _vm.businessData &&
            _vm.businessData.openType === "2" &&
            _vm.businessData.hasMailBtn
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "icongerenyouxiang",
                      "icon-color": "#3e90fe",
                      type: "text",
                    },
                    on: { click: _vm.handleMailDetailButtonClick },
                  },
                  [_vm._v(" 邮件详情 ")]
                )
              : _vm._e(),
            _vm.businessData && _vm.businessData.openType === "2"
              ? _c("BusinessButtons", {
                  ref: "businessButtons",
                  attrs: { "business-data": _vm.businessData },
                })
              : _vm._e(),
            _vm.showModel === 1
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "iconquanping",
                      "icon-color": "#3e90fe",
                      type: "text",
                    },
                    on: { click: _vm.toggleFullScreenClick },
                  },
                  [_vm._v(" 全屏 ")]
                )
              : _vm._e(),
            _vm.showModel === 10
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "18",
                      icon: "tuichuquanping",
                      "icon-color": "#F76B6B",
                      type: "text",
                    },
                    on: { click: _vm.toggleFullScreenClick },
                  },
                  [_vm._v(" 退出 ")]
                )
              : _vm._e(),
            _vm.showModel === 2
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "22",
                      icon: "iconshaixuantiaojianshanchu21",
                      "icon-color": "#F76B6B",
                      type: "text",
                    },
                    on: { click: _vm.handleBackIconClick },
                  },
                  [_vm._v(" 关闭 ")]
                )
              : _vm._e(),
            _vm.showModel === 3
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "22",
                      icon: "iconshaixuantiaojianshanchu21",
                      "icon-color": "#F76B6B",
                      type: "text",
                    },
                    on: { click: _vm.handleCloseSheetClick },
                  },
                  [_vm._v(" 关闭 ")]
                )
              : _vm._e(),
            _vm.showModel === 4
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      "icon-size": "22",
                      icon: "iconshaixuantiaojianshanchu21",
                      "icon-color": "#F76B6B",
                      type: "text",
                    },
                    on: { click: _vm.handleCloseDialog },
                  },
                  [_vm._v(" 关闭 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.voucherBtnVisible
        ? _c("vocDetailAlert", {
            ref: "vocdialog",
            attrs: {
              voucherId: _vm.voucherId,
              actionType: 2,
              fromCredential: true,
              formal: _vm.params,
              refreshFunc: "callback",
            },
          })
        : _vm._e(),
      _vm.voucherBtnVisible
        ? _c("relation-voc-dialog", {
            ref: "relationVocDialog",
            on: { handleVocRowClick: _vm.handleVocRowClick },
          })
        : _vm._e(),
      _c("relation-mail-dialog", { ref: "relationMailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }