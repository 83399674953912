var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "business-detail",
      class: { "business-detail__full-screen": _vm.isFullScreen },
    },
    [
      _c(
        "div",
        {
          staticClass: "detail-header",
          class: {
            "detail-header__full-screen": _vm.isFullScreen,
          },
        },
        [
          _c("BusinessHeader", {
            attrs: {
              "business-data": _vm.businessData,
              businessParams: _vm.businessParams,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.enLoading,
              expression: "enLoading",
            },
          ],
          staticClass: "detail-content",
          class: {
            "detail-content__full-screen": _vm.isFullScreen,
          },
          style: _vm.themeInfo.openIndexTab
            ? { height: `calc(100% - ${_vm.contentHeight}px)` }
            : { height: `calc(100% - ${_vm.contentHeight + 45}px)` },
        },
        [
          _vm.MultiTabColumn
            ? _c(
                "div",
                {
                  class:
                    _vm.strokeStyle === "2" ? "stroke-icon1" : "stroke-icon2",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openStroke.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("en-icon", {
                    class: { transform: !_vm.showStroke },
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      name: "iconshouqizhankaijiantou",
                      size: "12",
                      color: "#4694df",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.businessData
            ? _c(
                "div",
                {
                  staticClass: "detail-inner",
                  class: {
                    "detail-inner__full-screen": _vm.isFullScreen,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "business-form",
                      attrs: {
                        model: _vm.businessData,
                        "label-position": "left",
                        "label-width": "auto",
                      },
                    },
                    _vm._l(
                      _vm.businessData.showSections,
                      function (section, sectionIndex) {
                        return _c(
                          "div",
                          {
                            key: section.uuid,
                            staticClass: "section",
                            class: { "section__full-screen": _vm.isFullScreen },
                          },
                          [
                            section.name && section.name.length > 0
                              ? _c("div", { staticClass: "section-name" }, [
                                  _c("span", { staticClass: "color-block" }),
                                  _c(
                                    "span",
                                    { staticClass: "section-name-content" },
                                    [_vm._v(_vm._s(section.name))]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._l(section.areas, function (data, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.judgeRowDisplay(data),
                                      expression: "judgeRowDisplay(data)",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "wrapper",
                                      class: {
                                        "wrapper-mask-to-bounds":
                                          _vm.judgeRowMaskToBounds(data),
                                      },
                                    },
                                    _vm._l(data, function (column, colIndex) {
                                      return (
                                        _vm.MultiTabColumn
                                          ? column.fieldType !== 45
                                          : true
                                      )
                                        ? _c("business-column-rendering", {
                                            key: column.uuid,
                                            attrs: {
                                              column: column,
                                              businessData: _vm.businessData,
                                              isTab: false,
                                              validatorData: _vm.validatorData,
                                              "check-prop":
                                                "showSections." +
                                                sectionIndex +
                                                ".areas." +
                                                index +
                                                "." +
                                                colIndex,
                                            },
                                          })
                                        : _vm._e()
                                    }),
                                    1
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.businessData && _vm.MultiTabColumn
            ? _c(
                "div",
                {
                  class:
                    _vm.strokeStyle === "2"
                      ? "floating-window"
                      : "lateral-stroke",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "detail-inner",
                      class: {
                        "detail-inner__full-screen": _vm.isFullScreen,
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          staticClass: "business-form",
                          attrs: {
                            model: _vm.businessData,
                            "label-position": "left",
                            "label-width": "auto",
                          },
                        },
                        _vm._l(
                          _vm.businessData.showSections,
                          function (section, sectionIndex) {
                            return _c(
                              "div",
                              {
                                key: section.uuid,
                                staticClass: "section",
                                class: {
                                  "section__full-screen": _vm.isFullScreen,
                                },
                              },
                              _vm._l(section.areas, function (data, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.judgeRowDisplay(data),
                                        expression: "judgeRowDisplay(data)",
                                      },
                                    ],
                                    key: index,
                                  },
                                  _vm._l(data, function (column, colIndex) {
                                    return column.fieldType === 45
                                      ? _c("business-column-rendering", {
                                          key: column.uuid,
                                          staticClass: "wrapper",
                                          class: {
                                            "wrapper-mask-to-bounds":
                                              _vm.judgeRowMaskToBounds(data),
                                          },
                                          attrs: {
                                            column: column,
                                            isTab: true,
                                            businessData: _vm.businessData,
                                            validatorData: _vm.validatorData,
                                            "check-prop":
                                              "showSections." +
                                              sectionIndex +
                                              ".areas." +
                                              index +
                                              "." +
                                              colIndex,
                                          },
                                        })
                                      : _vm._e()
                                  }),
                                  1
                                )
                              }),
                              0
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.shouldShowButtonView
        ? _c(
            "div",
            {
              staticClass: "detail-bottom",
              class: {
                "detail-bottom__full-screen": _vm.isFullScreen,
                "detail-bottom__normal": !_vm.isFullScreen,
              },
            },
            [
              _c("BusinessBottom", {
                ref: "businessBottom",
                attrs: { businessData: _vm.businessData },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.businessData
        ? _c("page-switch", { attrs: { "business-data": _vm.businessData } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }