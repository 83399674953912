var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-bottom" },
    [
      _vm.businessData.mdInfo.shareUserName.length
        ? _c(
            "span",
            { staticClass: "share" },
            [
              _c("en-icon", {
                attrs: { name: "bus-btn12_1", color: "#F7BF27", size: "14" },
              }),
              _c("span", { staticClass: "share-name" }, [
                _vm._v(" " + _vm._s(_vm.businessData.mdInfo.shareUserName)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.taskSourceName
        ? _c("span", { staticClass: "source-name" }, [
            _c("span", { staticClass: "dot" }),
            _vm._v(" 创建来源: "),
            _c(
              "span",
              { staticClass: "source-text", on: { click: _vm.openSource } },
              [_vm._v(_vm._s(_vm.taskSourceName))]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "buttons" },
        _vm._l(_vm.flowButtons, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "btn-wrap" },
            [
              _c(
                "en-button",
                {
                  attrs: {
                    "background-color": _vm.isWfCustomButton(item.buttonType)
                      ? item.color
                      : "",
                    type: item.showType,
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleButtonClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.btnName) + " ")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("businessFunction", {
        ref: "businessFunction",
        attrs: {
          id: _vm.businessData.id,
          businessData: _vm.businessData,
          templateId: _vm.businessData.mdInfo.templateId,
          "p-id": _vm.businessData.mdInfo.pId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }