<!--
 * @Description:
 * @Author: 梁平贤
 * @Date: 2021/5/10 13:42
 -->
<template>

  <div class="page-switch" v-show="show" v-if="hasPageSwitchFunction">
    <div class="left" @click="leftClick">
      <en-icon name="icontupian-zuojiantou" color="#FFFFFF" size="18"></en-icon>
    </div>
    <div class="right" @click="rightClick">
      <en-icon name="icontupian-youjiantou" color="#FFFFFF" size="18"></en-icon>
    </div>
  </div>

</template>

<script>

import { hasClass } from "element-ui/src/utils/dom";
import { businessService } from "@/api/businessModel";
import { businessDetailPageSwitchRoute } from "@/components/businessDetail/helper.js";
import { BusinessDataOpenType, BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default {
  name: "PageSwitch",
  data() {
    return {
      show: false,
      parentY: 0,
      parentHeight: 0,
      timer: null,
      // 根据传进来的分页参数以及自身的数据决定(在没在分页参数里面)
      hasPageSwitchFunction: false,
      // 切换页面所需的参数
      pageSwitchData: {
        pageNo: 1, // 列表请求的当前页
        pageSize: 20, // size
        selectIndex: 1, // 选中的第几条数据index
        interfaceName: "", // 请求的接口名称
        params: {}, // 该接口所需的参数
        loadedData: [], // 已加载的数据
        idKey: "id", // 数据idkey
        businessTypeKey: "businessType", // 类型key
        noMoreData: false, // 没有更多数据了
        hasClicked: false // 是否点击了...有的不是点左右切换的,有的是, 是的话那就默认展示上一页,下一页
      }
    };
  },
  props: {
    businessData: {}
  },
  methods: {
    // 鼠标移动显示或隐藏箭头
    mousemove(e) {
      if (this.getParentNodeContainModal(e.target)) {
        this.show = false;
        return;
      }
      const y = e.clientY - this.parentY;
      const needShow = Math.abs(y - this.parentHeight / 2) < 200;
      this.show = needShow;
    },
    getParentNodeContainModal(node) {
      let result = false;
      while (node.parentNode) {
        if (hasClass(node, "el-dialog__wrapper")) {
          result = true;
          break;
        }
        node = node.parentNode;
      }
      return result;
    },
    // 计算箭头位置,防抖
    calculateContent() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.updateContentY();
      }, 1000);
    },
    // 加载分页数据
    async loadMoreDataForUp(isUp) {
      // 先尝试获取下数据,如果数据出错或者没有更多数据了,就不需要修改pageNo
      const tempPageNo = isUp ? this.pageSwitchData.pageNo - 1 : this.pageSwitchData.pageNo + 1;
      const tempParams = Object.assign(this.pageSwitchData.params, { pageNo: tempPageNo });
      this.$showLoading();
      try {
        const datas = await businessService.http.post(this.pageSwitchData.interfaceName, tempParams);
        if (datas.records.length === 0) {
          this.alertWithUp(isUp);
          this.pageSwitchData.noMoreData = true;
          return -1;
        }
        this.pageSwitchData.pageNo = tempPageNo;
        if (tempPageNo === datas.totalPages) {
          // 最后一页,标记无数据
          this.pageSwitchData.noMoreData = true;
        }
        const dataList = datas.records;
        // 推荐的index,可能会失败
        let suggestIndex = this.pageSwitchData.selectIndex;
        // 插入数据
        if (isUp) {
          this.pageSwitchData.loadedData.splice(0, 0, ...dataList);
          suggestIndex = dataList.length - 1;
        } else {
          this.pageSwitchData.loadedData.splice(this.pageSwitchData.loadedData.length - 1, 0, ...dataList);
          suggestIndex++;
        }
        return suggestIndex;
      } catch {
        return -1;
      } finally {
        this.$hideLoading();
      }
    },
    // 上一页
    async leftClick() {
      if (this.pageSwitchData.selectIndex === 0) {
        if (Number(this.pageSwitchData.pageNo) === 1) {
          // 没有上一页数据了
          this.alertWithUp(true);
          return;
        }
        // 加载更多数据
        const suggestIndex = await this.loadMoreDataForUp(true);
        if (suggestIndex !== -1) {
          // 切换建议index数据
          this.switchRoute(suggestIndex);
        }
      } else {
        // 切换上一条
        this.switchRoute(this.pageSwitchData.selectIndex - 1);
      }
    },
    // 下一页
    async rightClick() {
      if (this.pageSwitchData.selectIndex === this.pageSwitchData.loadedData.length - 1) {
        if (this.pageSwitchData.noMoreData) {
          this.alertWithUp(false);
          return;
        }
        // 加载更多数据
        const suggestIndex = await this.loadMoreDataForUp(false);
        if (suggestIndex !== -1) {
          // 切换建议index数据
          this.switchRoute(suggestIndex);
        }
      } else {
        // 切换下一条
        this.switchRoute(this.pageSwitchData.selectIndex + 1);
      }
    },
    // 提示
    alertWithUp(isUp) {
      this.$message.warning(isUp ? "这已经是第一条啦" : "这已经是最后一条啦");
    },

    // 切换数据 要考虑全屏 不 全屏的问题
    switchRoute(index) {
      // businessDetailPageSwitchRoute
      this.pageSwitchData.selectIndex = index;
      this.pageSwitchData.hasClicked = true;
      sessionStorage.setItem("businessPageSwitchData", JSON.stringify(this.pageSwitchData));
      const data = this.pageSwitchData.loadedData[index];
      const route = businessDetailPageSwitchRoute({
        id: data[this.pageSwitchData.idKey],
        businessType: data[this.pageSwitchData.businessTypeKey] || this.businessData.businessParams.businessType,
        openType: this.businessData.businessParams.openType,
        pageType: this.$route.query.pageType || this.businessData.businessParams.pageType,
        templateId: this.businessData.businessParams.templateId,
        sourcetype: this.businessData.businessParams.sourcetype
      });
      this.$router.replace(route);
    },
    //
    updateContentY() {
      this.$nextTick(() => {
        this.parentHeight = this.$el.parentNode.clientHeight;
        this.parentY = this.$el.parentNode.getBoundingClientRect().y;
      });
    },
    // 处理分页数据
    processPageData() {
      //  获取分页数据
      this.pageSwitchData = JSON.parse(sessionStorage.getItem("businessPageSwitchData"));
      if (!this.pageSwitchData) {
        return;
      }
      this.pageSwitchData.idKey = this.pageSwitchData.idKey || "id";
      this.pageSwitchData.businessTypeKey = this.pageSwitchData.businessTypeKey || "businessType";
      let hasPageSwitchFunction = false;
      try {
        // 当前数据id
        const id = this.businessData.id;
        const openType = String(this.businessData.businessParams.openType);
        const pageType = Number(this.businessData.businessParams.pageType);
        if ((openType === BusinessDataOpenType.view || openType === BusinessDataOpenType.readOnly)
          && (pageType === BusinessDataPageType.normal || openType === BusinessDataOpenType.view)) {
          if (this.pageSwitchData.loadedData[this.pageSwitchData.selectIndex][this.pageSwitchData.idKey] === id) {
            // 数据相同,则走分页逻辑
            hasPageSwitchFunction = true;
            if (this.pageSwitchData.hasClicked) {
              // 改变默认值
              this.show = true;
            }
          }
        }
      } catch {
        //
      }
      this.hasPageSwitchFunction = hasPageSwitchFunction;
    }
  },

  mounted() {
    this.updateContentY();
    this.processPageData();
    window.addEventListener("mousemove", this.mousemove);
    window.onresize = () => this.calculateContent();
  },
  beforeDestroy() {
    window.onresize = null;
    window.removeEventListener("mousemove", this.mousemove);
  }
};
</script>

<style scoped lang="scss">
  .page-switch {
    pointer-events: none;
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0px;
    top: calc(50% - 25px);
    display: flex;
    justify-content: space-between;
    //bug37398
    .right, .left {
      z-index: 1001;
      margin-left: 11px;
      margin-right: 11px;
      pointer-events: auto;
      width: 48px;
      height: 48px;
      background-color: #A9B5C688;
      line-height: 48px;
      border-radius: 50%;
      text-align: center;
      .en-icon {
        margin-top: 14px;
      }
    }
  }
</style>
