var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        "en-body-scroll": "",
        visible: _vm.dialogVisible,
        title: "查看关联凭证",
        "append-to-body": "",
        width: "1000px",
      },
      on: {
        close: function ($event) {
          _vm.dialogVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "relation-container" },
        [
          _c(
            "en-table",
            {
              attrs: {
                height: "400",
                data: _vm.dataList,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", align: "center" },
              }),
              _vm._l(_vm.fieldList, function (item) {
                return _c("table-column", {
                  key: item.field,
                  attrs: {
                    "is-home-page": "",
                    data: item,
                    "filter-sort-config": {},
                  },
                })
              }),
              _c("en-table-column", {
                attrs: { field: "status", label: "生成状态", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              color: _vm.formatStatus(row.status).color,
                            },
                          },
                          [_vm._v(_vm._s(_vm.formatStatus(row.status).name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }