<!--
 * @Author: zoujp
 * @Date: 2020-07-22 17:09:20
 * @LastEditTime: 2020-07-29 15:31:22
 * @LastEditors: Please set LastEditors
 * @Description: 收藏-引用
 * @FilePath: \user\src\components\businessDetail\components\detailBtn\collectEdit.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    :title="!isQuoteEdit ? '引用模板' : '编辑模板'"
    class="bus-collect-quote"
    width="1100px"
    :close-on-click-modal="false"
    @close="closeFn"
    :append-to-body="true"
  >
    <div v-if="quoteList.length === 0" class="loading" v-en-loading="loadingConfig">
    </div>
    <template v-else>
      <div class="quote-wrap" :class="isQuoteEdit ? 'isQuoteEdit' : ''">
        <div class="quote-temp" :class="{cur:item.isSelected}" v-for="(item,index) in quoteList" :key="index" @click="selectedItem(item)">
          <div class="quote-icon" :style="{backgroundColor:item.collectColor}">
            <en-icon
              name="shoucang-kuaijiecaozuo"
              size="16"
              color="#fff"
            >
            </en-icon>
          </div>
          <div class="quote-name"><span>{{ item.name }}</span></div>
          <div class="quote-time">{{ getCreateTime(item.createTime) }}</div>
          <div v-if="isQuoteEdit" class="quote-oper">
            <en-icon
              name="bianji-liebiao"
              size="16"
              class="quote-edit"
              color="#26C393"
              @click.native.stop="quoteEdit(item)"
            >
            </en-icon>
            <en-icon
              name="shanchu-liebiao"
              size="16"
              class="quote-del"
              @click.native.stop="quoteDelete(item)"
            >
            </en-icon>
          </div>
          <div class="quote-sele">
            <en-icon
              name="yixuan"
              size="11"
              color="#fff"
            >
            </en-icon>
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="save" v-show="!isQuoteEdit">
          确定
        </en-button>
      </div>
      <collectAdd ref="collect"
                  :business-data="businessData"
                  :is-quote="detailData.isQuote"
                  :param="detailData.data"
                  @complete="complete">
      </collectAdd>
    </template>
  </en-dialog>
</template>

<script>
import { Message } from "element-ui";
import dayjs from "dayjs";
import { btnService } from "@/api/businessModel";
import { collection } from "./data/index";
import collectAdd from "./collectAdd";

// 过滤
export default {
  name: "CollectQuote",
  components: { collectAdd },
  props: {
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    isQuoteEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      quoteList: [],
      detailData: {
        isQuote: false,
        data: null,
        complete: this.complete
      },
      loadingConfig: { type: "loading", subTitle: "加载中,请稍等..." }
    };
  },
  watch: {
    visiable() {
      if (this.visiable) {
        this.getQuoteList();
      }
    }
  },
  mounted() {
  },
  methods: {
    /**
     * @description: 收藏编辑
     * @item :  当前选择的收藏信息
     */
    quoteEdit(item) {
      this.detailData.data = item;
      this.detailData.isQuote = false;
      this.$refs.collect.visiable = true;
      this.detailData.complete = (() => {
        this.getQuoteList();
      });
    },
    /**
     * @description: 收藏删除
     * @item :  当前选择的收藏信息
     */
    async quoteDeleteComm(item) {
      await btnService.mdBatchDelCollectTemplate(item);
      Message.success({
        content: "删除成功",
        duration: 3
      });
      const index = this.quoteList.findIndex((itm) => {
        if (itm.id === item.id) {
          return true;
        }
        return false;
      });
      this.quoteList.splice(index, 1);
    },
    /**
     * @description: 收藏删除
     * @item :  当前选择的收藏信息
     */
    quoteDelete(item) {
      this.$confirm("确认删除当前模板吗?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.quoteDeleteComm(item);
      });
    },
    /**
     * @description: 获取收藏创建时间
     * @item :  当前选择的收藏信息
     */
    selectedItem(item) {
      if (!this.isQuoteEdit) {
        this.$set(item, "isSelected", true);
        this.quoteList.forEach((b) => {
          if (b.id !== item.id) {
            this.$set(b, "isSelected", false);
          }
        });
        this.$forceUpdate();
      }
    },
    /**
     * @description: 获取收藏创建时间
     * @time :  创建时间
     */
    getCreateTime(time) {
      return dayjs(time).format("YYYY-MM-DD");
    },
    /**
     * @description: 查询收藏列表
     */
    getQuoteList() {
      this.loadingConfig.subTitle = "加载中,请稍等...";
      const params = {
        templateId: this.businessData.mdInfo.templateId,
        pageNo: 1,
        pageSize: 100
      };
      btnService.mdQueryCollectTemplateList(params).then((response) => {
        this.quoteList = response.records || [];
        this.loadingConfig.subTitle = "无数据";
        this.loadingConfig.type = "NoData";
      }).catch((error) => {
        this.loadingConfig.subTitle = error.message;
      });
    },
    /**
     * @description: 关闭弹窗
     */
    closeFn() {
      this.visiable = false;
      this.$emit("close");
    },
    /**
     * @description: 保存
     */
    save() {
      const sele = this.quoteList.filter((itm) => itm.isSelected);
      if (sele?.length === 0) {
        Message.warning("请选择模板");
      } else {
        // 这里引用操作待续。。。
        this.detailData.data = sele[0];
        this.$refs.collect.visiable = true;
        this.detailData.isQuote = true;
        this.detailData.complete = ((data) => {
          this.visiable = false;
          collection.updateData(this.businessData, data);
        });
      }
    },
    complete(data) {
      this.detailData.complete(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.bus-collect-quote{
  .loading {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
    & {
      height: 300px;
    }
  }
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  .quote-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .quote-temp{
      width: 522px;
      height: 62px;
      border: 1px solid #E8ECF2;
      background-color: #fff;
      margin-top: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      .quote-icon{
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .quote-name{
        flex: 1;
        color: #636C78;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        text-align: left;
        margin-left: 10px;
      }
      .quote-time{
        font-size: 12px;
        color: #91A1B7;
        width: 100px;
      }
      .quote-oper{
        width: 60px;
        position:absolute;
        top: 20px;
        right: 20px;
        display: none;
        .quote-edit{
          margin-right: 15px;

        }
      }
    }
    .cur{
      .quote-sele{
        position:absolute;
        width: 34px;
        height: 34px;
        top:-17px;
        left:-17px;
        background-color:#3e90fe;
        transform: rotate(45deg);
        .en-icon{
          position: absolute;
          top: 11px;
          left: 21px;
          transform: rotate(-45deg);
        }
      }
    }
    .quote-temp:nth-of-type(1),.quote-temp:nth-of-type(2){
      margin-top: 0;
    }

  }
  .isQuoteEdit {
    .quote-temp:hover{
      background-color: #F5F8FC;
      .quote-oper{
        display: block;
      }
      .quote-time{
        display: none;
      }
    }
  }
}

</style>
