var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        "en-body-scroll": "",
        visible: _vm.dialogVisible,
        title: "查看关联邮件",
        width: "1000px",
      },
      on: {
        close: function ($event) {
          _vm.dialogVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "relation-container" },
        [
          _c(
            "en-table",
            {
              attrs: {
                height: "400",
                data: _vm.dataList,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", align: "center" },
              }),
              _vm._l(_vm.fieldList, function (column) {
                return _c("en-table-column", {
                  key: column.field,
                  attrs: {
                    "min-width": "130",
                    label: column.name,
                    prop: column.field,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  column.field === "hasAttach"
                                    ? row[column.field]
                                      ? "是"
                                      : "否"
                                    : row[column.field]
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }