/*
 * @Description: 流程按钮
 * @Author: 梁平贤
 * @Date: 2020/8/18 19:35
 */

import { ProcessButtonType } from "@/tools/classes/process/ProcessButton.js";
import { BillStatus } from "@/tools/classes/process/ProcessHeader";
import { BusinessDataOpenType, BusinessType } from "../data/BusinessCommonHeader";

export default {
  computed: {
    // 该展示的流程按钮
    flowButtons() {
      // 只有能编辑才加按钮
      if (this.businessData.businessType === BusinessType.pureBasic && this.businessData.pageEdit) {
        return [this.businessData.mdInfo.eventEntity.creatSubmitButton()];
      }
      let buttons = this.businessData?.mdInfo?.eventEntity?.showFlowButtons;
      if ((this.businessData.mdInfo.shareUserList && this.businessData.mdInfo.shareUserList.length > 0)) {
        // 共享的单据不展示编辑删除
        const notShowButtons = [ProcessButtonType.delete, ProcessButtonType.edit];
        buttons = buttons?.filter((button) => notShowButtons.indexOf(button.buttonType) === -1);
      }
      if (this.businessData?.openType === BusinessDataOpenType.add) {
        // 新增的时候不展示删除 编辑
        const notShowButtons = [ProcessButtonType.delete, ProcessButtonType.edit];
        buttons = buttons?.filter((button) => notShowButtons.indexOf(button.buttonType) === -1);
      }
      // 页面不能编辑不展示提交 再次提交
      if (!this.businessData?.pageEdit) {
        const notShowButtons = [ProcessButtonType.submitStart, ProcessButtonType.aginAdd];
        buttons = buttons?.filter((button) => notShowButtons.indexOf(button.buttonType) === -1);
      }
      // 在编辑状态或者是草稿时,不展示编辑
      if (this.businessData?.openType === BusinessDataOpenType.edit || this.businessData?.mdInfo?.billStatus === BillStatus.unCommit) {
        const notShowButtons = [ProcessButtonType.edit];
        buttons = buttons?.filter((button) => notShowButtons.indexOf(button.buttonType) === -1);
      }
      // 草稿状态时, 不展示编辑
      return buttons;
    }
  }
};
