var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "考勤记录",
        "append-to-body": "",
        "destroy-on-close": true,
        "close-on-click-modal": false,
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("div", { staticClass: "att-record" }, [
        _c("div", { staticClass: "info" }, [
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c("en-user-logo", {
                attrs: {
                  size: "40",
                  "user-name": _vm.attRecordDetail.userName,
                },
              }),
              _c("div", [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.attRecordDetail.userName)),
                ]),
                _c("span", { staticClass: "user-no" }, [
                  _vm._v("   " + _vm._s(_vm.attRecordDetail.userNo)),
                ]),
                _c("span", { staticClass: "dept-name" }, [
                  _vm._v("   " + _vm._s(_vm.attRecordDetail.deptName)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass: "icon-wrap",
                  style: { background: _vm.statusInfo.color },
                },
                [
                  _c("en-icon", {
                    attrs: {
                      size: 10,
                      color: "#fff",
                      name: _vm.statusInfo.icon,
                    },
                  }),
                ],
                1
              ),
              _c(
                "en-button",
                {
                  attrs: {
                    "icon-size": "16",
                    "icon-color": _vm.statusInfo.color,
                    type: "text",
                  },
                },
                [
                  _c("span", { style: { color: _vm.statusInfo.color } }, [
                    _vm._v(_vm._s(_vm.statusInfo.text)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "att-item" },
          [
            _c(
              "en-button",
              {
                attrs: {
                  "icon-size": "18",
                  icon: "shijian-xuanxiang",
                  "icon-color": "#aeaeae",
                  type: "text",
                },
              },
              [_vm._v("打卡时间 : ")]
            ),
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.attRecordDetail.recordTime)),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "att-item" },
          [
            _c(
              "en-button",
              {
                attrs: {
                  "icon-size": "18",
                  icon: "shijian-xuanxiang",
                  "icon-color": "#aeaeae",
                  type: "text",
                },
              },
              [_vm._v("应打卡时间 : ")]
            ),
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.attRecordDetail.workTime)),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "att-item" },
          [
            _c(
              "en-button",
              {
                attrs: {
                  "icon-size": "18",
                  icon: "dizhi-kaoqin",
                  "icon-color": "#aeaeae",
                  type: "text",
                },
              },
              [_vm._v("打卡地址 : ")]
            ),
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.attRecordDetail.address)),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }