var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-buttons" },
    [
      _vm._l(_vm.hotButtons, function (button) {
        return _c(
          "en-button",
          {
            key: button.btnKey,
            staticClass: "button",
            attrs: {
              "icon-size": "18",
              title: button.btnName,
              icon: button.processIcon,
              "icon-color": _vm.colorWithButtonType(button),
              type: _vm.btnStyle != "1" ? "white" : "text",
            },
            on: {
              click: function ($event) {
                return _vm.handleButtonClick(button)
              },
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.btnStyle != "1" ? button.btnName : "") + " "
            ),
          ]
        )
      }),
      _vm._l(_vm.fixedButtons, function (button) {
        return _c(
          "el-dropdown",
          { key: button.btnKey, on: { command: _vm.handleMenuClick } },
          [
            _c(
              "span",
              { staticClass: "el-dropdown-link" },
              [
                _c(
                  "en-button",
                  {
                    staticClass: "button",
                    attrs: {
                      "icon-size": "18",
                      icon: "bus-btn" + button.icon + "_1",
                      "icon-color": _vm.colorWithButtonType(button),
                      type: "text",
                    },
                  },
                  [_vm._v(" " + _vm._s(button.btnName) + " ")]
                ),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.showList(button.buttonType), function (menu) {
                return _c(
                  "el-dropdown-item",
                  { key: menu.btnKey, attrs: { command: menu } },
                  [
                    _c("span", { staticClass: "drop-name" }, [
                      _vm._v(_vm._s(menu.btnName)),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      }),
      _vm.coolButtons.length > 0
        ? _c(
            "el-dropdown",
            { staticClass: "more", on: { command: _vm.handleButtonClick } },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c("en-icon", {
                    staticClass: "drop-icon",
                    attrs: {
                      name: "iconfujian-gengduo",
                      size: "18",
                      color: "#3e90fe",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.coolButtons, function (button) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: button.btnKey,
                      staticClass: "more-drop-down-item",
                      attrs: { command: button },
                    },
                    [
                      _c("en-icon", {
                        staticClass: "drop-icon",
                        attrs: {
                          name: "bus-btn" + button.icon + "_1",
                          color: _vm.colorWithButtonType(button),
                          size: "18px",
                        },
                      }),
                      _c("span", { staticClass: "drop-name" }, [
                        _vm._v(_vm._s(button.btnName)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("businessFunction", {
        ref: "businessFunction",
        attrs: {
          businessData: _vm.businessData,
          id: _vm.businessData.id,
          templateId: _vm.businessData.mdInfo.templateId,
        },
      }),
      _c("collectAdd", {
        ref: "popCollectAdd",
        attrs: { businessData: _vm.businessData },
      }),
      _c("collectQuote", {
        ref: "popCollectEdit",
        attrs: { businessData: _vm.businessData, "is-quote-edit": true },
      }),
      _c("collectQuote", {
        ref: "popCollectQuote",
        attrs: { businessData: _vm.businessData },
      }),
      _vm.showaddChats
        ? _c("add-chats", {
            ref: "addPerson",
            attrs: { appendToBody: true, "create-team": "" },
            on: { change: _vm.sendMessage },
          })
        : _vm._e(),
      _c("att-record-dialog", { ref: "attRecordDialog" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }