<!--
 * @Author: zoujp
 * @Date: 2020-07-22 17:09:11
 * @LastEditTime: 2020-07-23 11:46:04
 * @LastEditors: Please set LastEditors
 * @Description: 收藏-创建模板
 * @FilePath: \user\src\components\businessDetail\components\detailBtn\collectAdd.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    :title="title"
    class="bus-collect-create"
    width="800px"
    :close-on-click-modal="false"
    @close="closeFn"
    append-to-body
  >
    <div v-if="loading" v-en-loading="loading" class="loading">
    </div>
    <template v-else>
      <div class="quote-create-form">
        <template v-if="!this.isQuote">
          <div class="row">
            <div class="label">模板名称</div>
            <div class="content"><el-input class="temp-name" v-model="name" placeholder="请输入"></el-input></div>
          </div>
          <div class="row">
            <div class="label">图标颜色</div>
            <div class="content" style="text-align:left;">
              <en-color-setting v-model="color"/>
            </div>
          </div>
          <div class="row">
            <div class="label">收藏字段</div>
            <div class="content" style="text-align:left;">
              <el-checkbox v-model="isHide">显示可收藏字段</el-checkbox>
            </div>
          </div>
        </template>
        <el-checkbox v-else-if="isQuote" style="margin-bottom: 15px" v-model="isHide">显示可引用字段</el-checkbox>
      </div>
      <div class="quote-create-list">
        <en-table :data="showListData" :height-config="{}" height="520px" :select-config="{checkMethod,checkDisabledMethod}" @select-change="handleSelectChange">
          <en-table-column type="selection" width="50"></en-table-column>
          <en-table-column
            prop="info.name"
            label="字段"
            width="180"
          >
          </en-table-column>
          <en-table-column
            prop="info.showValue"
            label="默认值"
            width="35%"
          >
          </en-table-column>
          <en-table-column
            prop="info.collectInstructions"
            label="说明"
          >
          </en-table-column>
        </en-table>
      </div>
      <div slot="footer">
        <en-button @click="save">
          确定
        </en-button>
      </div>
    </template>
  </en-dialog>
</template>

<script>

import { btnService } from "@/api/businessModel/index";
import { collection } from "./data/index";
// 过滤
export default {
  name: "CollectCreate",
  components: { },
  props: {
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    param: {
      type: Object
    },
    isQuote: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      name: "",
      color: "#677ea3",
      isHide: false,
      loading: false,
      title: "",
      collectInfos: [],
      collectData: [],
      collectDataId: "",
      listData: [],
      colors: [
        "#677ea3",
        "#12b7f5",
        "#5a99ef",
        "#92d300",
        "#f7bf27",
        "#ff5d5d",
        "#c7c7cc"
      ]
    };
  },
  computed: {
    showListData() {
      if (this.isHide) {
        return this.listData.filter((item) => !item.info.disabled);
      }
      return this.listData;
    }
  },
  watch: {
    visiable() {
      if (this.visiable) {
        if (this.param) {
          this.title = this.isQuote ? "引用模板" : "编辑模板";
          const param = { ...this.param };
          param.isEdit = this.isQuote ? 1 : 0;
          this.loading = true;
          btnService.mdQueryCollectTemplateInfo(param).then((response) => {
            response.collectCondition.forEach((item) => {
              item.disabled = Number(item.isAllowFlag) !== 0 || item?.collectInstructions.length > 0;
            });
            this.collectInfos = response.collectCondition;
            this.collectData = response.collectData;
            this.collectDataId = response.id;
            this.color = response.collectColor;
            this.name = response.name;
            this.isHide = true;
            this.listData = collection.showCollectInfos(response.collectCondition, this.collectData);
            this.loading = false;
          }).catch(() => {
            this.loading = false;
          });
        } else {
          this.title = "创建模板";
          this.isHide = true;
          collection.handle(this.businessData, (response) => {
            const { collectInfos, collectData } = response;
            this.collectInfos = collectInfos;
            this.collectData = collectData;
            this.listData = collection.showCollectInfos(this.collectInfos, this.collectData);
            this.name = this.businessData.mdInfo.templateName;
          });
        }
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗
     */
    closeFn() {
      this.visiable = false;
    },
    /**
     * @description: 保存
     */
    save() {
      if (!this.isQuote) {
        this.saveData();
      } else {
        this.$emit("complete", this.collectData, this.collectInfos);
      }
    },
    saveData() {
      if (this.name.length === 0) {
        this.$message("请输入名字");
        return;
      }
      const selectInfos = this.listData.filter((item) => Number(item.info.isChoose) === 0 && !item.info.disabled);
      if (selectInfos.length === 0) {
        this.$message("请至少选择一个字段");
        return;
      }
      const param = {};
      param.templateId = this.businessData.mdData.originData.templateId;
      param.version = this.businessData.mdData.originData.version;
      param.name = this.name;
      param.collectColor = this.color;
      if (this.color.length === 0) {
        param.collectColor = this.colors[0];
      } else {
        param.collectColor = this.color;
      }
      if (this.collectDataId.length > 0) {
        param.id = this.collectDataId;
      }
      param.collectData = JSON.stringify(this.collectData);
      param.collectCondition = JSON.stringify(this.collectInfos);
      this.$showLoading();
      btnService.mdSaveOrUpdateCollectTemplateInfo(param).then(() => {
        this.$message("处理成功");
        this.$emit("complete", this.collectData, this.collectInfos);
        this.visiable = false;
        this.$hideLoading();
      }).catch(() => {
        this.$hideLoading();
      });
    },
    checkMethod(e) {
      return e.row.info.isChoose === "0" || e.row.info.isChoose === 0;
    },
    checkDisabledMethod(e) {
      return e.row.info.disabled;
    },
    handleSelectChange(e) {
      this.listData.forEach((item) => {
        if (!item.info.disable) {
          item.info.isChoose = e.checked ? 0 : 1;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bus-collect-create{
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    & {
      height: 761px;
    }
  }
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  .quote-create-form {
    .row{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 44px;
      line-height: 44px;
      .label{
        width: 48px;
        color: #333333;
        font-weight: 400;
      }
      .content{
        display: flex;
        align-items: center;
        height: 44px;
        flex:1;
        .en-color-setting {
          /deep/ .content {
            padding-left: 0;
          }
        }
      }
    }
  }
}

</style>
