<template>
   <en-dialog
      :visible="visible"
      title="考勤记录"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="visible = false"
    >
      <div class="att-record">
        <div class="info">
          <div class="user-info">
            <en-user-logo size="40" :user-name="attRecordDetail.userName"></en-user-logo>
            <div>
              <div class="name">{{ attRecordDetail.userName }}</div>
              <span class="user-no"> &nbsp; {{ attRecordDetail.userNo }}</span>
              <span class="dept-name"> &nbsp; {{ attRecordDetail.deptName }}</span>
            </div>
          </div>
          <div>
            <span class="icon-wrap" :style="{background: statusInfo.color}">
              <en-icon :size="10" color="#fff" :name="statusInfo.icon"></en-icon>
            </span>
            <en-button  icon-size="16"
                :icon-color="statusInfo.color"
                 type="text">
                  <span :style="{color: statusInfo.color}">{{ statusInfo.text }}</span>
                 </en-button>
          </div>
        </div>
        <div class="att-item">
           <en-button icon-size="18"
                 icon="shijian-xuanxiang" icon-color="#aeaeae"
                 type="text">打卡时间 :  </en-button>
            <span class="text">{{ attRecordDetail.recordTime }}</span>
        </div>
        <div class="att-item">
           <en-button icon-size="18"
                 icon="shijian-xuanxiang" icon-color="#aeaeae"
                 type="text">应打卡时间 :  </en-button>
            <span class="text">{{ attRecordDetail.workTime }}</span>
        </div>
        <div class="att-item">
           <en-button icon-size="18"
                 icon="dizhi-kaoqin" icon-color="#aeaeae"
                 type="text">打卡地址 :  </en-button>
            <span class="text">{{ attRecordDetail.address }}</span>
        </div>
      </div>
    </en-dialog>
</template>

<script>
import { btnService } from "@/api/businessModel";

export default {
  name: "attRecordDialog",
  data() {
    return {
      visible: false,
      attRecordDetail: {}
    };
  },
  computed: {
    statusInfo() {
      const statusMap = {
        1: {
          text: "正常",
          icon: "kaoqin-zhengchang",
          color: "#26c393"
        },
        2: {
          text: "迟到",
          icon: "kaoqin-chidao",
          color: "#ffad2c"
        },
        3: {
          text: "早退",
          icon: "kaoqin-zaotui",
          color: "#ffad2c"
        },
        4: {
          text: "不在GPS范围内打卡",
          icon: "kaoqin-yichang",
          color: "#f76b6b"
        },
        5: {
          text: "未打卡",
          icon: "kaoqin-weidaka",
          color: "#f76b6b"
        },
        6: {
          text: "不在允许打卡的时间范围内",
          icon: "kaoqin-yichang",
          color: "#f76b6b"
        },
        7: {
          text: "非工作日打卡",
          icon: "kaoqin-yichang",
          color: "#9367eb"
        },
        11: {
          text: "异常",
          icon: "kaoqin-yichang",
          color: "#f76b6b"
        },
        12: {
          text: "旷工",
          icon: "kaoqin-kuanggong",
          color: "#f76b6b"
        },
        17: {
          text: "缺勤",
          icon: "kaoqin-queqin",
          color: "#f76b6b"
        }
      };
      return statusMap[this.attRecordDetail.status] || {};
    }
  },
  methods: {
    async attQueryRecordDetail(id) {
      const res = await btnService.attQueryRecordDetail({ id });
      this.attRecordDetail = res;
    },
    open(id) {
      this.visible = true;
      this.attQueryRecordDetail(id);
    }
  }
};
</script>

<style lang="scss">
.att-record {
  padding: 10px 0 60px 0;
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #aeaeae;
    padding: 14px;
    background: #fbfbfb;
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    margin-bottom: 15px;
    .user-info {
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 14px;
      color: #636c78;
      margin: 0 0 8px 8px;
    }
    .icon-wrap {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
      margin-right: 6px;
    }
  }
  .att-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #636c78;
    line-height: 24px;
    padding: 5px 0;
    .text {
      margin-left: 5px;
    }
  }
}
</style>
