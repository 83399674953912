<!--
 * @Description: 业务建模头部组件
 * @Author: 梁平贤
 * @Date: 2020/8/18 14:42
 -->
 <template>
  <div>
  <div class="business-header">
    <div class="header-left">
      <en-icon v-if="showModel === 1" class="back-icon" name="iconfanhuishangcengji" size="18px"
              color="#A9B5C6" @click.native="handleBackIconClick"></en-icon>
      <div class="header-icon" v-if="showTemplateName && showTemplateIcon">
        <en-icon  :name="showTemplateIcon" size="16"
              color="#FCFCFC"></en-icon>
      </div>
      <div class="header-icon" v-if="showTemplateName && !showTemplateIcon">
        <en-icon name="iconmoban-yewujianmo1" size="16"
              color="#FCFCFC"></en-icon>
      </div>
      <span class="name">{{ showTemplateName }}</span>
      <billStatus v-if="businessData" :billStatus="businessData.mdInfo.billStatus"
                  :billStatusName="this.businessData.mdInfo.billStatusName"></billStatus>

      <span class="parent"
            v-if="businessData && businessData.mdInfo.parentId">{{"上级:"+ businessData.mdInfo.parentName}}</span>
     </div>
    <div class="right">
      <en-button v-if="voucherBtnVisible && businessData.openType === '2'" icon-size="18"
                 icon="yiyouhangcheng" icon-color="#3e90fe"
                 type="text" @click="handleVoucherButtonClick"> 关联凭证
      </en-button>
      <en-button v-if="businessData && businessData.mdData && businessData.mdData.originData.tripId  && businessData.openType === '2'" icon-size="18"
                 icon="yiyouhangcheng" icon-color="#3e90fe"
                 type="text" @click="handleTripButtonClick"> 行程单
      </en-button>
      <en-button v-if="businessData && businessData.openType === '2' && businessData.mdData && businessData.mdData.originData.attRecordId || businessParams.attRecordId" icon-size="18"
                 icon="guanliankaoqin" icon-color="#3e90fe"
                 type="text" @click="handleAttRecordButtonClick"> 关联考勤
      </en-button>
      <en-button v-if="businessData && businessData.openType === '2' && businessData.hasMailBtn" icon-size="18"
                 icon="icongerenyouxiang" icon-color="#3e90fe"
                 type="text" @click="handleMailDetailButtonClick"> 邮件详情
      </en-button>
      <BusinessButtons v-if="businessData && businessData.openType === '2'" ref="businessButtons" :business-data="businessData"></BusinessButtons>
      <!-- 全屏控制按钮等 -->
      <en-button v-if="showModel === 1" icon-size="18" icon="iconquanping" icon-color="#3e90fe"
                 type="text" @click="toggleFullScreenClick"> 全屏
      </en-button>
      <en-button v-if="showModel === 10" icon-size="18" icon="tuichuquanping" icon-color="#F76B6B"
                 type="text" @click="toggleFullScreenClick"> 退出
      </en-button>
      <en-button v-if="showModel === 2" icon-size="22" icon="iconshaixuantiaojianshanchu21" icon-color="#F76B6B"
                 type="text" @click="handleBackIconClick"> 关闭
      </en-button>
      <en-button v-if="showModel === 3" icon-size="22" icon="iconshaixuantiaojianshanchu21" icon-color="#F76B6B"
                 type="text" @click="handleCloseSheetClick"> 关闭
      </en-button>
      <en-button v-if="showModel === 4" icon-size="22" icon="iconshaixuantiaojianshanchu21" icon-color="#F76B6B"
                 type="text" @click="handleCloseDialog"> 关闭
      </en-button>
    </div>
  </div>
    <!-- 编辑查看 -->
    <vocDetailAlert
      ref="vocdialog"
      v-if="voucherBtnVisible"
      :voucherId="voucherId"
      :actionType="2"
      :fromCredential="true"
      :formal="params"
      refreshFunc="callback"
    ></vocDetailAlert>
    <relation-voc-dialog v-if="voucherBtnVisible" @handleVocRowClick="handleVocRowClick" ref="relationVocDialog"></relation-voc-dialog>
    <relation-mail-dialog ref="relationMailDialog"></relation-mail-dialog>
</div>
</template>

<script>
import { componentMixins } from "en-js";
import { businessService } from "@/api/businessModel";
import { BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";
import vocDetailAlert from "@/views/intelligentAccounting/components/vocDetailAlert";
import BusinessButtons from "./components/BusinessButtons";
import RelationMailDialog from "./components/relationMailDialog";
import relationVocDialog from "./components/relationVocDialog";
import billStatus from "../billStatus";

export default {
  name: "BusinessHeader",
  mixins: [componentMixins],
  components: {
    BusinessButtons,
    billStatus,
    RelationMailDialog,
    vocDetailAlert,
    relationVocDialog
  },
  mounted() {
    this.showModel = Number(this.businessParams.pageType);
  },
  data() {
    return {
       /**
     * 显示模式
     * @returns {number}
     * 模式1:渲染返回箭头,能选择是否全屏
     * 模式2:拥有关闭按钮,返回到上一个路由
     * 模式3:拥有关闭按钮,直接关闭该浏览器页签
     * 模式4:弹窗,关闭按钮,关闭弹窗
     */
      voucherId: "",
      showModel: Number(this.businessParams.pageType),
      params: {
        accountId: this.businessData?.id,
        pageNo: 1,
        pageSize: 10,
        filterFields: [
          {
            field: "status",
            prop: "status",
            property: "status",
            name: "生成状态",
            fieldType: 5,
            type: "filter",
            dataSource: 3,
            nameVariable: "status",
            valueText: "待生成,生成异常",
            value: "待生成,生成异常",
            column: {
              title: "生成状态",
              field: "status",
              prop: "status",
              property: "status",
              nameVariable: "status"
            }
        }],
        sortFields: ""
      }
    };
  },
  props: {
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 业务建模的控制参数 有些东西需要在请求接口前初始化
    businessParams: Object
  },
  computed: {
    // 显示的模板名称
    showTemplateName() {
      if (this.businessData) {
        console.log(this.businessData, "asdasd");
        return this.businessData.mdInfo.templateName || this.businessData.mdInfo.name;
      }
      return this.businessParams.templateName;
    },
    showTemplateIcon() {
      if (sessionStorage.getItem("IconType")) {
        return sessionStorage.getItem("IconType");
      }
      return "";
    },
    voucherBtnVisible() {
      const voucherCode = "040";
      let hasPower = false;
      const menuList = this.$store.getters["main/menuList"];
      menuList.forEach((item) => {
        item.dataList?.forEach((data) => {
          if (data.code === voucherCode) {
            hasPower = true;
          }
        });
      });
      return this.businessData?.mdData.originData.vcStatus === "2" && hasPower;
    }
  },
  methods: {
    handleVocRowClick({ id }) {
      this.voucherId = id;
      this.$refs.vocdialog.visible = true;
    },
    async handleVoucherButtonClick() {
      const { records } = await businessService.vcQueryVoucherListByBusinessKey({
        businessKey: this.businessParams.id
      });

      if (records.length === 0) {
        this.$message.warning("无数据");
      } else if (records.length === 1) {
        this.voucherId = records[0].id;
        this.$refs.vocdialog.visible = true;
      } else {
        // 打开弹窗
        this.$refs.relationVocDialog.show(this.businessParams.id);
      }
    },
    // 关联邮件详情
    async handleMailDetailButtonClick() {
      const { id } = this.businessData;
      const { records } = await businessService.getMdRefMailList({ id });
      if (records.length > 1) {
        this.$refs.relationMailDialog.show(id);
      } else {
        const url = `/mailbox?id=${records[0].mailId}&businessKey=${id}&seeflg=readOnly`;
        window.open(url, "_black");
      }
    },
    // 跳转关联的行程单
    handleTripButtonClick() {
      this.$router.push({
        path: "/business-travel/trip/tripDetail",
        query: { tripId: this.businessData.mdData.originData.tripId }
      });
    },
    // 关联考勤
    handleAttRecordButtonClick() {
      const attRecordId = this.businessData.mdData.originData.attRecordId || this.businessParams.attRecordId;
      this.$refs.businessButtons.handleAttRecordButtonClick(attRecordId);
    },
    // 关闭弹窗
    handleCloseDialog() {
      const dialogComponent = this._MxFindComponentUpward(this, "BusinessDetail").$parent;
      dialogComponent.handleClose();
    },
    // 关闭浏览器页签
    handleCloseSheetClick() {
      window.location.href = "about:blank";
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    // 返回按钮点击
    handleBackIconClick() {
      const source = this.businessData?.businessParams.sourcetype;
      console.log(source, "sourcesourcesource");
      // if (source && source.indexOf("list") === 0) {
      //   // 只有列表来的才有这个特殊逻辑
      //   const path = {
      //     list_normal: "/businessModel/list",
      //     list_resources: "/businessModel/resources",
      //     list_calendar: "/businessModel/calendar",
      //     list_gantt: "/businessModel/gantt"
      //   }[source] || "/businessModel/list";
      //   this.$router.replace({
      //     path,
      //     query: {
      //       templateId: this.$route.query.templateId,
      //       viewType: this.$route.query.viewType || ""
      //     }
      //   });
      // } else {
        this.$router.back();
      // }
    },
    fullscreen() {
      const elem = document.body;
      if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.requestFullScreen) {
        elem.requestFullscreen();
      } else {
        // 浏览器不支持全屏API或已被禁用
      }
    },
    exitFullscreen() {
      const elem = document;
      if (elem.webkitCancelFullScreen) {
        elem.webkitCancelFullScreen();
      } else if (elem.mozCancelFullScreen) {
        elem.mozCancelFullScreen();
      } else if (elem.cancelFullScreen) {
        elem.cancelFullScreen();
      } else if (elem.exitFullscreen) {
        elem.exitFullscreen();
      } else {
        // 浏览器不支持全屏API或已被禁用
      }
    },
    // 切换全屏
    toggleFullScreenClick() {
      const query = this.$route.query;
      if (this.showModel === 1) {
        query.pageType = BusinessDataPageType.toggleFullscreen;
        // this.fullscreen();
      } else {
        query.pageType = BusinessDataPageType.normal;
        // this.exitFullscreen();
      }
      this.showModel = Number(query.pageType);
      this.$eventHub.$emit("CHANGE_LAYOUT", this.showModel === 1 ? 0 : 2);
      const { href } = this.$router.resolve({
        path: this.$route.path,
        query
      });
      window.history.replaceState("", "", href);
      // replace会刷新路由
      // this.$router.replace({
      //   path: this.$route.path,
      //   query
      // });
    }
  }
};
</script>

<style scoped lang="scss">
  .business-header {
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to right,#daeef4,#e2e1fa,#eceff9);
    .header-left{
      display: flex;
      align-items: center;
      .header-icon{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 11px;
      background: linear-gradient(-30deg, #3E90FE, #5FA5FF);
      border-radius: 8px;
    }
    }
    .right {
      float: right;
      display: flex;
      height: 100%;
      line-height: 49px;
      .business-buttons {
        margin-right: 20px;
      }
    }
    .back-icon {
      cursor: pointer;
      vertical-align: middle;
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      vertical-align: middle;
    }
    .bill-status {
      margin-left: 11px;
    }
    .parent {
      margin-left: 12px;
      display: inline-block;
      min-width: 62px;
      padding: 0 12px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      font-size: 12px;
      border-radius: 4px;
      color: #4794DF;
      background-color: #4794DF1A;
    }
  }

</style>
