import dayjs from "dayjs";
import { AssignmentOptions, BusinessFieldType } from "../../../../data/BusinessCommonHeader";
import DateColumn from "../../../../data/columns/date/DateColumn";

export default class Collection {
  handle(businessData, resolve) {
    let collectInfos = null;
    let collectData = null;
    try {
      const infos = businessData.mainInfos.filter((item) => item.displayable === true);
      collectInfos = infos.map((item) => this.collectInfo(item, businessData));
      collectData = this.collectData(infos);
    } catch (e) {
      console.log(e.message);
    }
    resolve({ collectInfos: collectInfos || [], collectData: collectData || {} });
  }

  updateData(businessData, data) {
    businessData.mainInfos.forEach((item) => {
      item.updateValueWithOptions(data[item.field], AssignmentOptions.server);
    });
  }

  showCollectInfos(infos, data) {
    const arr = [];
    infos.forEach((item) => {
      if (item.fieldType === BusinessFieldType.detailParent) {
        item.disabled = true;
        item.showValue = this.showValueWithInfo(item, data);
        arr.push(item);
        if (Array.isArray(item.sub)) {
          arr.push(...item.sub);
        }
      } else {
        item.showValue = this.showValueWithInfo(item, data);
        arr.push(item);
      }
    });
    arr.forEach((item) => {
      item.disabled = item?.collectInstructions?.length > 0 || item.fieldType === BusinessFieldType.detailParent;
    });
    return arr.map((info) => ({ info }));
  }

  showValueWithInfo(info, data) {
    const value = data[info.field];
    if (!value) {
      return "";
    }
    if (info.fieldType === BusinessFieldType.inputCommon
      || info.fieldType === BusinessFieldType.inputAmount
      || info.fieldType === BusinessFieldType.multiText
      || info.fieldType === BusinessFieldType.inputNumber) {
      return value || "";
    }
    if (info.fieldType === BusinessFieldType.select) {
      if (Array.isArray(value)) {
        return value.map((item) => item.name).split(",");
      }
    } else if (info.fieldType === BusinessFieldType.date) {
      const date = new DateColumn(info).getDatePickerFormat();
      return value ? dayjs(value).format(date) : "";
    } else if (info.fieldType === BusinessFieldType.address) {
      if (value) {
        const arr = [];
        arr.push(value.districtName || "");
        arr.push(value.cityName || "");
        arr.push(value.provinceName || "");
        arr.push(value.address || "");
        return arr.join("");
      }
    } else if (info.fieldType === BusinessFieldType.detailParent) {
      if (Array.isArray(value)) {
        info.name = `${info.name} ${value.length}条记录`;
        if (Array.isArray(info.sub) && value.length > 0) {
          info.sub.forEach((item1) => {
            item1.showValue = this.showValueWithInfo(item1, value[0]);
          });
        }
      }
    }
    return "";
  }

  collectData(infos) {
    const param = {};
    infos.forEach((info) => {
      if (this.canCollect(info)) {
        Object.assign(param, info.paramsForSubmit);
      } else if (info.fieldType === BusinessFieldType.detailParent) {
        Object.assign(param, info.paramsForSubmit);
        const datas = [];
        if (Array.isArray(info.subDetails)) {
          info.subDetails.forEach((area) => {
            const param1 = {};
            const infos1 = area.fields.filter((item) => item.displayable === true);
            if (Array.isArray(infos1)) {
              infos1.forEach((info1) => {
                Object.assign(param1, info1.paramsForSubmit);
              });
            }
            datas.push(param1);
          });
        }
        param[info.field] = datas;
      }
    });
    return param;
  }

  collectInfo(info, businessData) {
    const param = {};
    param.field = info.field;
    param.name = info.name;
    param.appFieldName = info.appFieldName || "";
    param.fieldType = info.fieldType;
    param.isAllowFlag = "1";
    if (info.fieldType === BusinessFieldType.inputNumber) {
      param.percentFlag = info.percentFlag;
    }
    if (info.fieldType === BusinessFieldType.inputNumber
      || info.fieldType === BusinessFieldType.inputAmount) {
      param.percentFlag = info.suffix;
      param.reserverDecimal = info.reserverDecimal;
    }
    if (info.fieldType === BusinessFieldType.date) {
      param.timeFormat = info.timeFormat;
      param.dateRange = info.dateRange;
    }
    if (info.fieldType === BusinessFieldType.detailParent) {
      if (info.subDetails.length > 0) {
        const infos = info.subDetails[0].fields.filter((item) => item.displayable === true);
        const collectInfos = infos.map((item) => this.collectInfo(item, businessData));
        param.sub = collectInfos;
      }
    } else {
      param.collectInstructions = this.collectError(info, businessData);
      param.isChoose = param.collectInstructions.length === 0 ? 0 : 1;
      param.isAllowFlag = param.collectInstructions.length > 0 ? 1 : 0;
    }
    return param;
  }

  canCollect(info) {
    if (info.fieldType === BusinessFieldType.inputCommon
      || info.fieldType === BusinessFieldType.inputAmount
      || info.fieldType === BusinessFieldType.multiText
      || info.fieldType === BusinessFieldType.inputNumber
      || info.fieldType === BusinessFieldType.select
      || info.fieldType === BusinessFieldType.multiText
      || info.fieldType === BusinessFieldType.date
      || info.fieldType === BusinessFieldType.address) {
      return true;
    }
    return false;
  }

  collectError(info, businessData) {
    if (!this.canCollect(info)) {
      return "字段设置的控件，不支持";
    }
    if (info.fieldType === BusinessFieldType.inputCommon) {
      if (info.codeRule?.length > 0) {
        return "设置了编码规则，不支持";
      }
      if (info.inputConfig?.length > 0 || parseInt(info.formatCheck, 10) > 0) {
        return "设置了控制条件，不支持";
      }
    }
    if (info.fieldType === BusinessFieldType.select) {
      if (info.isCascade === "0" && info.prefixField.length > 0) {
        return "设置了级联属性，不支持";
      }
    }
    if (info.fieldType === BusinessFieldType.select
      || info.fieldType === BusinessFieldType.multiSelect) {
      const ids = info?.dataArea?.map((item) => item.id);
      if (ids?.length === 0) {
        return "";
      }
      const result = info.comlumValue?.map((item) => ids.indexOf(item.id) !== -1);
      if (result.indexOf(false)) {
        return "不在指定基础数据范围内，不支持";
      }
    }
    const templateHandle = businessData.templateHandle;
    if (templateHandle?.calculateHandle?.fields?.indexOf(info.field) !== -1 && templateHandle.calculateHandle) {
      return "设置了计算公式，不支持";
    }
    if (templateHandle.summaryUpFields && templateHandle?.summaryUpFields?.indexOf(info.field) !== -1) {
      return "设置了汇总关系，不支持";
    }
    if (info.getObjField?.length > 0) {
      return "设置了自动取值，不支持";
    }
    if (info.defaults?.length > 0) {
      return "设置了默认值，不支持";
    }
    return "";
  }
}
