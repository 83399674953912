<!--
 * @Description: 业务建模底部控件
 * @Author: 梁平贤
 * @Date: 2020/8/18 15:03
 -->
<template>
  <div class="business-bottom">
    <span class="share" v-if="businessData.mdInfo.shareUserName.length">
      <en-icon name="bus-btn12_1" color="#F7BF27" size="14"></en-icon>
      <span class="share-name"> {{businessData.mdInfo.shareUserName}}</span>
    </span>
    <span class="source-name" v-if="taskSourceName">
      <span class="dot"></span>
      创建来源:
      <span class="source-text" @click="openSource">{{taskSourceName}}</span>
    </span>
    <div class="buttons">
      <div class="btn-wrap" v-for="(item,index) in flowButtons" :key="index">
        <!--流程自定义按钮取它设置的颜色-->
        <en-button
          :background-color="isWfCustomButton(item.buttonType) ? item.color : ''"
          :type="item.showType"
          @click.native.stop="handleButtonClick(item)"
        >
          {{ item.btnName }}
        </en-button>
      </div>
    </div>
    <businessFunction :id="businessData.id" ref="businessFunction" :businessData="businessData" :templateId="businessData.mdInfo.templateId" :p-id="businessData.mdInfo.pId"></businessFunction>
  </div>
</template>

<script>

import { ProcessButtonType } from "@/tools/classes/process/ProcessButton.js";
import { otherEntranceRoute } from "@/components/businessDetail/helper";
import businessFunction from "../../mixins/buttonFunction/businessFunction.vue";
// import BusinessButtonFunctions from "../../mixins/buttonFunction/BusinessButtonFunctions";
import flowButtons from "../../mixins/ContainFlowButtons";
import { BusinessDataOpenType } from "../../data/BusinessCommonHeader";

export default {
  name: "BusinessBottom",
  components: {
    businessFunction
  },
  data() {
    return {
    };
  },
  props: {
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [flowButtons],
  methods: {
    // 触发按钮
    handleButtonClick(button) {
      console.log(button, "buttonbutton");
      this.$refs.businessFunction.triggerButtonFunction(button);
    },
    // 自定义按钮判断
    isWfCustomButton(type) {
      return type === ProcessButtonType.wfcustom;
    },
    // 测试代办回调
    flowButtonProcessed() {
      console.log("代办回调");
    },
    openSource() {
      const { taskSource, taskSourceId, taskSourceTemplateId } = this.businessData.mdData?.originData;
      if (taskSource === "3") {
        this.$router.push(otherEntranceRoute({
          id: taskSourceId,
          templateId: taskSourceTemplateId
        }));
      }
    }
  },
  computed: {
      // 任务来源名字
    taskSourceName() {
      const taskSource = this.businessData.mdData?.originData?.taskSource;
      if (!taskSource) {
        return false;
      }
      const sourceNameMap = {
        1: "我的任务",
        2: "沟通",
        3: "业务数据"
      };
      return sourceNameMap[taskSource];
    }
  },
  mounted() {
    // 列表点击编辑进来的
    if (this.businessData.openType === BusinessDataOpenType.edit && (!this.businessData.businessParams.version || this.businessData.businessParams.version.length === 0)) {
      this.$refs.businessFunction.businessEdit();
    }
  }
};
</script>

<style scoped lang="scss">
  .business-bottom {
    font-size: 12px;
    height: 100%;
    .share {
      margin-left: 20px;
      line-height: 63px;
      .en-icon {
        vertical-align: middle;
      }
      .share-name {
        vertical-align: middle;
      }
    }
    .source-name {
      font-size: 12px;
      color: #606060;
      margin-left: 20px;
      line-height: 63px;
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 4px;
        background: #49bef3;
        margin-right: 4px;
      }
      .source-text {
        cursor: pointer;
      }
    }
    .buttons {
      float: right;
      margin: 0 20px;
      height: 100%;
      line-height: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-wrap {
        margin-left: 6px;
      }
    }

  }
</style>
