/*
 * @Description: 目前场景下,按钮颜色写死....
 * @Author: 梁平贤
 * @Date: 2020/8/21 11:06
 */

import { ProcessButtonType } from "./ProcessButton";

const fixedColorForButtonType = function fixedColorForButtonType(type) {
  const blue = "#3e90fe";
  const green = "26C393";
  const red = "#F76B6B";
  const yellow = "#FFAD2C";
  const grey = "#A9B5C6";
  const lightBlue = "#49BEF2";
  const colorMap = {
    [ProcessButtonType.add]: blue,
    [ProcessButtonType.edit]: green,
    [ProcessButtonType.disable]: red,
    [ProcessButtonType.enable]: green,
    [ProcessButtonType.delete]: red,
    [ProcessButtonType.recover]: green,
    [ProcessButtonType.export]: green,
    [ProcessButtonType.print]: blue,
    [ProcessButtonType.import]: yellow,
    [ProcessButtonType.log]: grey,
    [ProcessButtonType.share]: yellow,
    [ProcessButtonType.collect]: yellow,
    [ProcessButtonType.addtask]: green,
    [ProcessButtonType.transfer]: "",
    [ProcessButtonType.invoice]: blue,
    [ProcessButtonType.previewPlan]: green,
    [ProcessButtonType.eyeSkyX]: green,
    [ProcessButtonType.openDoor]: green,
    [ProcessButtonType.tripExpense]: green,
    [ProcessButtonType.returnReceipt]: "",
    [ProcessButtonType.comment]: "",
    [ProcessButtonType.giveLike]: "",
    [ProcessButtonType.copy]: lightBlue,
    [ProcessButtonType.QRCodeShare]: "",
    [ProcessButtonType.custom]: "",
    [ProcessButtonType.other]: "",
    [ProcessButtonType.communicate]: yellow
  };
  const color = colorMap[type];
  if (color && color.length !== 0) {
    return color;
  }
  return blue;
};

export default fixedColorForButtonType;
