var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPageSwitchFunction
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "page-switch",
        },
        [
          _c(
            "div",
            { staticClass: "left", on: { click: _vm.leftClick } },
            [
              _c("en-icon", {
                attrs: {
                  name: "icontupian-zuojiantou",
                  color: "#FFFFFF",
                  size: "18",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right", on: { click: _vm.rightClick } },
            [
              _c("en-icon", {
                attrs: {
                  name: "icontupian-youjiantou",
                  color: "#FFFFFF",
                  size: "18",
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }