<!--
 * @Description: 业务按钮
 * @Author: 梁平贤
 * @Date: 2020/8/19 11:12
 -->
<template>
  <div class="business-buttons">
    <en-button v-for="button in hotButtons"
               :key="button.btnKey"
               class="button"
               icon-size="18"
               :title="button.btnName"
               :icon="button.processIcon"
               :icon-color="colorWithButtonType(button)"
               :type="btnStyle != '1' ? 'white' : 'text'"
               @click="handleButtonClick(button)">
              {{  btnStyle != '1' ? button.btnName : '' }}
    </en-button>
    <!--固化按钮-->
    <el-dropdown @command="handleMenuClick" v-for="button in fixedButtons" :key="button.btnKey">
      <span class="el-dropdown-link">
        <en-button class="button"
                   icon-size="18"
                   :icon="'bus-btn'+button.icon+'_1'"
                   :icon-color="colorWithButtonType(button)"
                   type="text">
      {{ button.btnName }}
        </en-button>
          </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="menu in showList(button.buttonType)"
                          :key="menu.btnKey"
                          :command="menu">
          <span class="drop-name">{{menu.btnName}}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!--更多按钮-->
    <el-dropdown class="more" @command="handleButtonClick" v-if="coolButtons.length > 0">
          <span class="el-dropdown-link">
             <en-icon name="iconfujian-gengduo" size="18" class="drop-icon" color="#3e90fe"></en-icon>
          </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="button in coolButtons"
                          :key="button.btnKey"
                          :command="button"
        class="more-drop-down-item">
          <en-icon :name="'bus-btn'+button.icon+'_1'" :color="colorWithButtonType(button)" size="18px" class="drop-icon">
          </en-icon>
          <span class="drop-name">{{ button.btnName }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <businessFunction ref="businessFunction" :businessData="businessData" :id="businessData.id" :templateId="businessData.mdInfo.templateId"></businessFunction>
    <collectAdd ref="popCollectAdd" :businessData="businessData"></collectAdd>
    <collectQuote ref="popCollectEdit" :businessData="businessData" :is-quote-edit="true"></collectQuote>
    <collectQuote ref="popCollectQuote" :businessData="businessData"></collectQuote>
    <add-chats ref="addPerson" :appendToBody="true" create-team v-if="showaddChats"  @change="sendMessage"></add-chats>
    <att-record-dialog ref="attRecordDialog"></att-record-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { componentMixins } from "en-js";
import { ProcessButtonType } from "@/tools/classes/process/ProcessButton";
import { BillStatus } from "@/tools/classes/process/ProcessHeader";
import fixedColorForButtonType from "@/tools/classes/process/FixedProcessButtonColor";
import businessFunction from "@/components/businessDetail/mixins/buttonFunction/businessFunction.vue";
import addChats from "@/views/chat/view/addChats";
import { btnService } from "@/api/businessModel";
import BsUtils from "@/views/businessModel/list/data/utils";
import { BusinessDataOpenType } from "../../../data/BusinessCommonHeader";
import collectAdd from "./collectAdd";
import collectQuote from "./collectQuote";
import attRecordDialog from "./attRecordDialog";

export default {
  name: "BusinessButtons",
  components: {
    collectAdd,
    collectQuote,
    businessFunction,
    addChats,
    attRecordDialog
  },
  mixins: [componentMixins],
  mounted() {
    // 按钮的平铺和收起
    if (!this.businessData.mdInfo.btnArrange || Number(this.businessData.mdInfo.btnArrange) === 1) {
      this.mostShowButtonCount = 4;
    } else {
      this.mostShowButtonCount = 0;
    }
  },
  data() {
    return {
      btnStyle: "",
      // UI控制,最多展示的按钮个数,其它都收入更多 当然特殊情况: 当收入的图标只有一个时,直接展示出来
      mostShowButtonCount: 4,
      showaddChats: false,
      // 收藏菜单
      collectList: [
        {
          btnKey: "collectAdd",
          btnName: "创建",
          click: this.btnCollectAdd
        },
        {
          btnKey: "collectQuote",
          btnName: "引用",
          click: this.btnCollectQuote
        },
        {
          btnKey: "collectEdit",
          btnName: "编辑",
          click: this.btnCollectEdit
        }
      ]
    };
  },
  props: {
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
      // 关联考勤
    handleAttRecordButtonClick(attRecordId) {
      this.$refs.attRecordDialog.open(attRecordId);
    },
    closeBunissDialog() {
      let parentComp = this.$parent;
      while (parentComp) {
        if (parentComp.$options.name === "BusinessDialog") {
          parentComp.close();
          return;
        }
        parentComp = parentComp.$parent;
      }
    },
    addChatsFn(userList) {
      const params = {
          mainId: this.businessData.id,
          businessType: 2,
          templateId: this.businessData.mdInfo.templateId,
          userList: JSON.stringify(userList)
      };
      btnService.getImSession(params).then((resp) => {
        this.$store.dispatch("chat/setCurrSession", `team-${ resp.sessionId}`);
        this.closeBunissDialog();
        this.$router.push({
          path: "/chat",
          query: { id: `team-${ resp.sessionId}` }
        });
      });
    },
    // 触发按钮
    handleButtonClick(button) {
      BsUtils.beforeEmitClick(this, this.$refs.businessFunction.triggerButtonFunction.bind("", button), button);
    },
    // 颜色
    colorWithButtonType(button) {
      if (button.buttonType === "other") {
        return button.colour;
      }
      return fixedColorForButtonType(button.buttonType);
    },
    // 收藏和聊天的列表
    showList(buttonType) {
      if (buttonType === ProcessButtonType.collect) {
        return this.collectList;
      }
      return this.chatList;
    },
    // 收藏和聊天的列表点击
    handleMenuClick(menu) {
      console.log(menu);
      if (menu.click) {
        menu.click.call(this, menu, this.businessData.mdInfo);
      }
    },
    // 收藏新增
    btnCollectAdd(item) {
      console.log(item);
      this.$refs.popCollectAdd.visiable = true;
    },
    // 收藏编辑
    btnCollectEdit(item) {
      console.log(item);
      this.$refs.popCollectEdit.visiable = true;
      this.isQuoteEdit = true;
    },
    // 收藏引用
    btnCollectQuote(item) {
      console.log(item);
      this.$refs.popCollectQuote.visiable = true;
    },
    // 发送消息
    async sendMessage(userlist) {
      console.log(userlist);
      if (!userlist.length) return;
      const accounts = userlist.map((item) => item.imUserId) || [];
      this.addChatsFn(accounts);
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    // 是否显示按钮
    shouldShowButtons() {
      if (this.businessData.openType === BusinessDataOpenType.readOnly || this.businessData.openType === BusinessDataOpenType.add
        || (this.businessData.mdInfo.shareUserList && this.businessData.mdInfo.shareUserList.length > 0)) {
        return false;
      }
      return true;
    },
    // 所有该展示的按钮
    businessButtons() {
      if (!this.shouldShowButtons) {
        return [];
      }
      // 启用停用天眼查按钮业务建模详情不展示
      const excludeBtnKey = ["enable", "disable", "setLetter"];
      const eventEntity = this.businessData.mdInfo.eventEntity;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.btnStyle = eventEntity.btnStyle;
      let allShowButtons = [...eventEntity.showBusinessButtons];
      if (this.businessData.mdInfo.billStatus === BillStatus.unCommit) {
        // 暂存置空
        allShowButtons = [];
      }
      // 把收藏和沟通依次插入到能展示的最后的位置
      const insertButtonList = this.fixedButtons;
      // 这里为什么要+1,是因为多展示一个就不需要更多了,意味着只要是收起来,那里面肯定是两个按钮
      if (allShowButtons.length + insertButtonList.length > this.mostShowButtonCount + 1) {
        // 长度超标
        allShowButtons.splice(this.mostShowButtonCount - insertButtonList.length, 0, ...insertButtonList);
      } else {
        // 紧跟其后
        allShowButtons.push(...insertButtonList);
      }
      return allShowButtons.filter((item) => !excludeBtnKey.includes(item.btnKey));
    },
    //  该展示到货架上的按钮 称为热销按钮 不包含固化按钮
    hotButtons() {
      if (!this.shouldShowButtons) {
        return [];
      }
      let allButtons = [...this.businessButtons];
      console.log(allButtons, "hotButtonshotButtonshotButtons");
      allButtons = allButtons.filter((button) => button.buttonType !== ProcessButtonType.collect && button.buttonType !== ProcessButtonType.communicate);
      if (allButtons.length + this.fixedButtons.length === this.mostShowButtonCount + 1) {
        return allButtons;
      }
      if (this.mostShowButtonCount < this.fixedButtons.length) {
        return [];
      }
      return allButtons.slice(0, this.mostShowButtonCount - this.fixedButtons.length);
    },
    // 固定处理的两个按钮
    fixedButtons() {
      if (!this.shouldShowButtons) {
        return [];
      }
      const insertButtonList = [];
      // 因为现在收藏和沟通都没做,先屏蔽
      const eventEntity = this.businessData.mdInfo.eventEntity;
      // 如果有收藏就加收藏
      const collectionButton = eventEntity.giveMeButton(ProcessButtonType.collect);
      const hasCollect = eventEntity.mdBtnList.find((itm) => itm.btnKey === "collect");
      if (collectionButton && hasCollect) {
        // insertButtonList.push(collectionButton); 收藏先不做
      }
      // 上帝之手制造沟通按钮
      insertButtonList.push(eventEntity.creatCommunicateButton());
      return insertButtonList;
    },
    //  该藏起来的button 称为cool
    coolButtons() {
      if (!this.shouldShowButtons) {
        return [];
      }
      if (this.businessButtons.length < this.mostShowButtonCount + 1) {
        return [];
      }
      let allButtons = [...this.businessButtons];
      allButtons = allButtons.filter((button) => button.buttonType !== ProcessButtonType.collect && button.buttonType !== ProcessButtonType.communicate && this.hotButtons.find((hb) => hb.btnKey));
      allButtons = allButtons.filter((btn) => !this.hotButtons.find((hbtn) => hbtn.btnKey === btn.btnKey));
      // if (allButtons.length === this.mostShowButtonCount + 1) {
      //   return [];
      // }
      // if (this.mostShowButtonCount < this.fixedButtons.length) {
      //   return allButtons;
      // }
      // if (this.fixedButtons.length + allButtons.length + this.hotButtons.length === this.mostShowButtonCount + 1) {
      //   return [];
      // }
      return allButtons;
    },
    // 沟通列表
    chatList() {
      const chatList = [];
      if (this.businessData.mdInfo.sourceIcon === "1" && this.businessData.mdInfo.relationSessionId && this.businessData.mdInfo.relationSessionId.length > 0) {
        chatList.push({
          btnKey: "sourceMsg",
          btnName: "来源对话"
        });
      }
      chatList.push({
        btnKey: "businessMsg",
        btnName: "业务对话",
        click(menu, mdInfo) {
          console.log(menu, mdInfo);
          if (String(mdInfo.isEnableFlow) === "1" || mdInfo.sessionId) {
            // 审批沟通，直接跳转
            this.addChatsFn([this.userInfo.userId]);
            const att = this._MxFindComponentUpward(this, "AttendanceDetails");
            if (att) {
              att.businessDialog = false;
            }
          } else {
            this.showaddChats = true;
            this.$nextTick(() => {
              this.$refs.addPerson.open();
            });
          }
        }
      });
      return chatList;
    }

  }
};
</script>

<style scoped lang="scss">
  .more-drop-down-item {
    width: auto;
  }
  .drop-icon {
    vertical-align: middle;
  }

  .drop-name {
    vertical-align: middle;
    margin-left: 20px;
  }

  .fixed-button-name {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 12px;
  }

  .business-buttons {
    height: 100%;
    align-items: center;
    display: flex;
    .button {
      line-height: 49px;
      margin-left: 20px;
    }
    .more {
      margin-left: 20px;
    }
  }
</style>
