<template>
  <en-dialog
    en-body-scroll
    :visible="dialogVisible"
    title="查看关联凭证"
    append-to-body
    width="1000px"
    @close="dialogVisible = false"
  >
    <div class="relation-container">
      <en-table
        height="400"
        :data="dataList"
        :page-config="{ pageModel, changeMethod: handlePageChanged }"
        @row-click="handleRowClick"
      >
        <en-table-column type="index" align="center"></en-table-column>
        <table-column
          v-for="item in fieldList"
          is-home-page
          :key="item.field"
          :data="item"
          :filter-sort-config="{}"
        ></table-column>
        <en-table-column field="status" label="生成状态" width="150">
          <template v-slot="{ row }">
            <el-tag :color="formatStatus(row.status).color">{{
              formatStatus(row.status).name
            }}</el-tag>
          </template>
        </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { businessService } from "@/api/businessModel";
import tableColumn from "@/components/en-table-extra/tableColumn";
// 关联查看
export default {
  name: "RelationMailDialog",
  components: {
    tableColumn
  },
  props: {
    relationData: {
      type: Object,
      default() {
        return {};
      }
    },
    fieldList: {
      type: Array,
      default() {
        return [
          {
            field: "accountName",
            name: "账套",
            fieldType: "1",
            filter: false,
            sort: false
          },
          {
            field: "accountingEntityName",
            name: "记账主体",
            fieldType: "1",
            filter: false,
            sort: false
          },
          {
            field: "voucherNo",
            name: "凭证号",
            fieldType: "1",
            filter: false,
            sort: false
          },
          {
            field: "voucherDataTypeName",
            name: "凭证类别",
            fieldType: "1",
            filter: false,
            sort: false,
            show: true
          },
          {
            field: "refCode",
            name: "业务编号",
            fieldType: "1",
            filter: false,
            sort: false
          },
          {
            field: "busTemplateName",
            name: "业务模板",
            fieldType: "1",
            filter: false,
            sort: false
          },
          {
            field: "totalD",
            name: "借方合计",
            fieldType: "4",
            filter: false,
            sort: false
          },
          {
            field: "totalC",
            name: "贷方合计",
            fieldType: "4",
            filter: false,
            sort: false
          },
          {
            field: "produceDate",
            name: "记账日期",
            fieldType: "7",
            timeFormat: "8",
            filter: false,
            sort: false
          }
          // {
          //   field: "status", name: "生成状态"
          // }
        ];
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    formatStatus(val) {
      const statusName = {
        "000": "待审核",
        "001": "已审核",
        "002": "生成异常",
        "003": "未提交"
      };
      const statusColor = {
        "000": "#179eda",
        "001": "#606060",
        "002": "#f35d5e",
        "003": "#4fd2c2"
      };
      return {
        color: statusColor[val],
        name: statusName[val]
      };
    },
    show(id) {
      this.dialogVisible = true;
      this.id = id;
      this.getVoucherList();
    },
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      this.getVoucherList();
    },
    async getVoucherList() {
      const data = await businessService.vcQueryVoucherListByBusinessKey({
        ...this.pageModel,
        businessKey: this.id
      });
      Object.assign(this.pageModel, data);
      this.dataList = data.records;
    },
    handleRowClick({ row }) {
      this.$emit("handleVocRowClick", row);
    }
  }
};
</script>

<style lang="scss">
.relation-container {
  padding-bottom: 20px;
  .vxe-cell {
    font-size: 14px;
    line-height: 1;
  }
  .el-tag {
    color: #fff;
  }
}
</style>
