var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "bus-collect-create",
          attrs: {
            visible: _vm.visiable,
            title: _vm.title,
            width: "800px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _vm.loading
            ? _c("div", {
                directives: [
                  {
                    name: "en-loading",
                    rawName: "v-en-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "loading",
              })
            : [
                _c(
                  "div",
                  { staticClass: "quote-create-form" },
                  [
                    !this.isQuote
                      ? [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("模板名称"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c("el-input", {
                                  staticClass: "temp-name",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.name,
                                    callback: function ($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("图标颜色"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content",
                                staticStyle: { "text-align": "left" },
                              },
                              [
                                _c("en-color-setting", {
                                  model: {
                                    value: _vm.color,
                                    callback: function ($$v) {
                                      _vm.color = $$v
                                    },
                                    expression: "color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("收藏字段"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content",
                                staticStyle: { "text-align": "left" },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.isHide,
                                      callback: function ($$v) {
                                        _vm.isHide = $$v
                                      },
                                      expression: "isHide",
                                    },
                                  },
                                  [_vm._v("显示可收藏字段")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm.isQuote
                      ? _c(
                          "el-checkbox",
                          {
                            staticStyle: { "margin-bottom": "15px" },
                            model: {
                              value: _vm.isHide,
                              callback: function ($$v) {
                                _vm.isHide = $$v
                              },
                              expression: "isHide",
                            },
                          },
                          [_vm._v("显示可引用字段")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "quote-create-list" },
                  [
                    _c(
                      "en-table",
                      {
                        attrs: {
                          data: _vm.showListData,
                          "height-config": {},
                          height: "520px",
                          "select-config": {
                            checkMethod: _vm.checkMethod,
                            checkDisabledMethod: _vm.checkDisabledMethod,
                          },
                        },
                        on: { "select-change": _vm.handleSelectChange },
                      },
                      [
                        _c("en-table-column", {
                          attrs: { type: "selection", width: "50" },
                        }),
                        _c("en-table-column", {
                          attrs: {
                            prop: "info.name",
                            label: "字段",
                            width: "180",
                          },
                        }),
                        _c("en-table-column", {
                          attrs: {
                            prop: "info.showValue",
                            label: "默认值",
                            width: "35%",
                          },
                        }),
                        _c("en-table-column", {
                          attrs: {
                            prop: "info.collectInstructions",
                            label: "说明",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { slot: "footer" }, slot: "footer" },
                  [
                    _c("en-button", { on: { click: _vm.save } }, [
                      _vm._v(" 确定 "),
                    ]),
                  ],
                  1
                ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }