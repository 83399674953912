<template>
  <en-dialog
    en-body-scroll
    :visible="dialogVisible"
    title="查看关联邮件"
    width="1000px"
    @close="dialogVisible = false"
  >
    <div class="relation-container">
      <en-table
        height="400"
        :data="dataList"
        :page-config="{ pageModel, changeMethod: handlePageChanged }"
        @row-click="handleRowClick"
      >
        <en-table-column type="index" align="center"></en-table-column>
        <en-table-column
          v-for="column in fieldList"
          min-width="130"
          :key="column.field"
          :label="column.name"
          :prop="column.field"
        >
          <template v-slot="{ row }">
            {{
              column.field === "hasAttach"
                ? row[column.field]
                  ? "是"
                  : "否"
                : row[column.field]
            }}
          </template>
        </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { businessService } from "@/api/businessModel";

// 关联查看
export default {
  name: "RelationMailDialog",
  props: {
    relationData: {
      type: Object,
      default() {
        return {};
      }
    },
    fieldList: {
      type: Array,
      default() {
        return [
          {
            field: "sendTime",
            name: "发送时间"
          },
          {
            field: "sender",
            name: "发件人"
          },
          {
            field: "receiver",
            name: "收件人"
          },
          {
            field: "title",
            name: "主题"
          },
          {
            field: "hasAttach",
            name: "是否有附件"
          }
        ];
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    show(id) {
      this.dialogVisible = true;
      this.id = id;
      this.getMdRefMailList();
    },
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      this.getMdRefMailList();
    },
    async getMdRefMailList() {
      const data = await businessService.getMdRefMailList({
        ...this.pageModel,
        id: this.id
      });
      Object.assign(this.pageModel, data);
      this.dataList = data.records;
    },
    handleRowClick({ row }) {
      const url = `/mailbox?id=${row.mailId}&businessKey=${this.id}&seeflg=readOnly`;
      window.open(url, "_black");
    }
  }
};
</script>

<style lang="scss">
.relation-container {
  padding-bottom: 20px;
  .vxe-cell {
    font-size: 14px;
    line-height: 1;
  }
}
</style>
