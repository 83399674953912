<!--
 * @Description: 状态展示
 * @Author: 梁平贤
 * @Date: 2020/8/18 16:07
 -->
<template>
    <div class="bill-status" v-if="billStatus && billStatus.length"  :style="billStatusStyle"> {{showName}} </div>
</template>

<script>
import { BillStatus } from "@/tools/classes/process/ProcessHeader.js";

export default {
  name: "billStatus",
  props: {
    billStatus: [String],
    billStatusName: [String]
  },
  computed: {
    showName() {
      if (this.billStatusName && this.billStatusName.length > 0) {
        return this.billStatusName;
      }
      switch (this.billStatus) {
        case BillStatus.unCommit:
          return "未提交";
        case BillStatus.pending:
          return "待处理";
        case BillStatus.approving:
          return "审批中";
        case BillStatus.over:
          return "已结束";
        case BillStatus.terminated:
          return "已终止";
        case BillStatus.suspension:
          return "暂缓";
        case BillStatus.approved:
          return "已审批";
        case BillStatus.obsoleted:
          return "已作废";
        case BillStatus.committed:
          return "已提交";
        default:
          return "";
      }
    },
    // 状态
    billStatusStyle() {
      let color = "#fff";
      switch (this.billStatus) {
        case BillStatus.unCommit:
          color = "#4794DF";
          break;
        case BillStatus.committed:
        case BillStatus.approved:
          color = "#27C494";
          break;
        case BillStatus.approving:
        case BillStatus.pending:
        case BillStatus.suspension:
          color = "#FFAE2D";
          break;
        case BillStatus.over:
        case BillStatus.obsoleted:
          color = "#646C78";
          break;
        case BillStatus.terminated:
          color = "#F86C6C";
          break;
        default:
          color = "#fff";
          break;
      }
      const style = {
        color: "#fff",
        "background-color": `${color}`// 10%透明度
      };
      return style;
    }
  }
};
</script>

<style scoped>
 .bill-status {
   display: inline-block;
   min-width: 62px;
   height: 26px;
   line-height: 26px;
   text-align: center;
   font-size: 11px;
   border-radius:4px;
 }
</style>
