/*
 * @Description: 返回,保存等
 * @Author: 梁平贤
 * @Date: 2020/9/9 08:58
 */

import { btnService } from "@/api/businessModel";

export default {
  beforeRouteLeave(to, from, next) {
    // 只有编辑才可能会保存
    // 删除缓存的切换页面数据
    sessionStorage.removeItem("businessPageSwitchData");
    if (this.businessData && this.businessData.needSave()) {
      this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "保存",
        cancelButtonText: "放弃修改"
      })
        .then(async() => {
          this.$showLoading();
          try {
            const params = this.businessData.paramsForSubmit({ isSave: true });
            await btnService.mdSubmitData(params);
          } catch {
            return;
          } finally {
            this.$hideLoading();
          }
          this.$message.success("操作成功");
          // 防止下次再进入这个钩子
          this.businessData.isSubmitted = true;
          next(true);
        })
        .catch((action) => {
          if (action === "cancel") {
            next(true);
          }
        });
      return;
    }
    next(true);
  }
};
