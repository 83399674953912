var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "bus-collect-quote",
          attrs: {
            visible: _vm.visiable,
            title: !_vm.isQuoteEdit ? "引用模板" : "编辑模板",
            width: "1100px",
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _vm.quoteList.length === 0
            ? _c("div", {
                directives: [
                  {
                    name: "en-loading",
                    rawName: "v-en-loading",
                    value: _vm.loadingConfig,
                    expression: "loadingConfig",
                  },
                ],
                staticClass: "loading",
              })
            : [
                _c(
                  "div",
                  {
                    staticClass: "quote-wrap",
                    class: _vm.isQuoteEdit ? "isQuoteEdit" : "",
                  },
                  _vm._l(_vm.quoteList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "quote-temp",
                        class: { cur: item.isSelected },
                        on: {
                          click: function ($event) {
                            return _vm.selectedItem(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "quote-icon",
                            style: { backgroundColor: item.collectColor },
                          },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: "shoucang-kuaijiecaozuo",
                                size: "16",
                                color: "#fff",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "quote-name" }, [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                        ]),
                        _c("div", { staticClass: "quote-time" }, [
                          _vm._v(_vm._s(_vm.getCreateTime(item.createTime))),
                        ]),
                        _vm.isQuoteEdit
                          ? _c(
                              "div",
                              { staticClass: "quote-oper" },
                              [
                                _c("en-icon", {
                                  staticClass: "quote-edit",
                                  attrs: {
                                    name: "bianji-liebiao",
                                    size: "16",
                                    color: "#26C393",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.quoteEdit(item)
                                    },
                                  },
                                }),
                                _c("en-icon", {
                                  staticClass: "quote-del",
                                  attrs: {
                                    name: "shanchu-liebiao",
                                    size: "16",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.quoteDelete(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "quote-sele" },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: "yixuan",
                                size: "11",
                                color: "#fff",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { attrs: { slot: "footer" }, slot: "footer" },
                  [
                    _c(
                      "en-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isQuoteEdit,
                            expression: "!isQuoteEdit",
                          },
                        ],
                        on: { click: _vm.save },
                      },
                      [_vm._v(" 确定 ")]
                    ),
                  ],
                  1
                ),
                _c("collectAdd", {
                  ref: "collect",
                  attrs: {
                    "business-data": _vm.businessData,
                    "is-quote": _vm.detailData.isQuote,
                    param: _vm.detailData.data,
                  },
                  on: { complete: _vm.complete },
                }),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }